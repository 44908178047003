import {Authentication} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import React, {useEffect, useState} from "react";
import API from "../../../../shared/API";
import {Organisation_Receiver} from "../../../../context/organisation";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Groups = ({organisation, history}) => {
  const [isLoading, setLoading] = useState(true);
  const [selected, setSelected] = useState("Global");
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getData();
  }, [organisation]);
  const getData = () => {
    Promise.all([
      API.org.groups.all(organisation.data._id)
    ]).then((res) => {
      setGroups(res[0]);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const createNewGroup = (data) => {
    setLoading(true);
    API.org.groups
    .add(organisation.data._id, data)
    .then((res) => {
      // setAdd(false);
      getData();
    })
    .catch((err) => {
      let errorMessage =
        "There was an unknown network error while s data from the server";
      if (err.response !== undefined) {
        if (err.response.data.message !== undefined) {
          errorMessage = err.response.data.message;
        }
      }
      console.log(errorMessage);
      // setError(errorMessage);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  const goToGroup = id => {
    history.push(`${organisation._urlPrefix}/team/security_groups/${id}`)
  };

  const tabs = [{ name: "Global" }, { name: "Custom" }];

  return (
    <div>
      {Authentication.can("sarstuff:global:admins") && <nav className="-mb-px flex space-x-8 ml-2">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => setSelected(tab.name)}
            className={classNames(
              selected === tab.name
                ? "border-amber-500 text-amber-600"
                : "border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-500",
              "whitespace-nowrap py-4 px-2 border-b-2 font-medium text-sm cursor-pointer tracking-wider"
            )}
          >
            {tab.name}
          </div>
        ))}
      </nav>}
      {selected === "Global" && (<div>
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
          <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
              Users
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{groups.filter(a => a.isGlobal).map((g) => {
            return (
              <tr
                key={g._id}
                onClick={() => goToGroup(g._id)}
                className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
              >
                <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">{g.name}</td>
                <td className="px-2 py-2 text-md text-gray-200">
                  {g.description}
                </td>
                <td className="px-2 py-2 text-md text-center text-gray-200">
                  {g.total_users}
                </td>
              </tr>
            );
          })}</tbody>
        </table>
      </div>)}
      {selected === "Custom" && (<div>
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
          <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
              Users
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{groups.filter(a => !a.isGlobal).map((g) => {
            return (
              <tr
                key={g._id}
                onClick={() => goToGroup(g._id)}
                className="items-center divide-x-2 divide-gray-700 cursor-pointer hover:bg-gray-900"
              >
                <td className="px-2 py-2 text-md text-gray-200 whitespace-nowrap">{g.name}</td>
                <td className="px-2 py-2 text-md text-gray-200">
                  {g.description}
                </td>
                <td className="px-2 py-2 text-md text-center text-gray-200">
                  {g.total_users}
                </td>
              </tr>
            );
          })}</tbody>
        </table>
      </div>)}
    </div>
  );
};
export default Organisation_Receiver(Groups, (organisation) => {
  return {organisation};
});